import React from "react";
// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  FluentProvider,
  teamsLightTheme,
  tokens,
} from "@fluentui/react-components";
import { useEffect } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { app } from "@microsoft/teams-js";
import Home from "./Home";
import Help from "./Help";
import { useTeams } from "@microsoft/teamsfx-react";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { theme } = useTeams({})[0];
  useEffect(() => {
    app.initialize().then(() => {
      // Hide the loading indicator.
      app.notifySuccess();
    });
  }, []);
  return (
    <FluentProvider
      theme={
        theme || {
          ...teamsLightTheme,
          colorNeutralBackground3: "#eeeeee",
        }
      }
      style={{ background: tokens.colorNeutralBackground3 }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/help" element={<Help />} />
        </Routes>
      </Router>
    </FluentProvider>
  );
}
