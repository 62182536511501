import { app } from "@microsoft/teams-js";
import axios from "axios";
import { useEffect, useState } from "react";
import { isIOS, isAndroid } from "react-device-detect";
import "./Home.css";

export default function Home() {
  const [data, setStats] = useState({
    is_admin_user: "",
    active_plan_name: "",
    has_invoice_info: "",
    has_company_info: "",
    api_call_logs: {
      active_plan_name: "",
      plan_name: "",
      total_api_calls: "",
      chatgpt_count: "",
      file_search_count: "",
      inquiry_mail_count: "",
      file_upload_count: "",
      inquiry_upload_count: "",
    },
    user_numbers: {
      number_of_all_users: "",
      number_of_free_users_quota: "",
      number_of_free_users: "",
      number_of_paid_users_quota: "",
      number_of_paid_users: "",
    },
    invoice_info: {
      invoice_plan_name: "",
      invoice_plan_amount: "",
      invoice_paid_users_quota: "",
      invoice_paid_users_amount: "",
      invoice_paid_users_amount_total: "",
      api_calls_amount: "",
      invoice_shipping_amount: "",
      total_amount: "",
      tax_amount: "",
    },
    company_info: {
      company_name: "",
      zip_code: "",
      address: "",
      representatives_name: "",
      mail_address: "",
      phone_number: "",
      invoice_shipping_method: "",
    },
    front_session_url: "",
    channel_id: "",
  });

  useEffect(() => {
    (async () => {
      app.initialize().then(async () => {
        app.notifyAppLoaded();

        const context = await app.getContext();
        const channel_id = context.channel?.id;
        const body = {
          ms_tenant_id: context?.user?.tenant?.id,
          ms_user_id: context?.user?.id,
        };

        const appApiEndpoint = process.env.REACT_APP_API_ENDPOINT!;
        const response = await axios.post(
          `${appApiEndpoint}/api/ms-teams/home`,
          body
        );
        const data = await response.data;
        data["channel_id"] = channel_id;
        await setStats(data);

        app.notifySuccess();
      });
    })();
  }, []);

  const windowOpenToFrontSessionUrl = () => {
    (async () => {
      window.open(
        `${data.front_session_url}&channel_id=${encodeURI(data.channel_id)}`
      );
    })();
  };

  return (
    <div className="narrow page-padding">
      {(() => {
        if (data.is_admin_user !== "") {
          return (
            <div>
              <h2>KIRARINN-AIからのお知らせ</h2>
              <hr></hr>
              <ul>
                <li>
                  <a
                    href="https://elfin-crop-e4b.notion.site/e1a9c67715fd4f4590a8d57eb7a0e1e9"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    新機能が追加されました！
                  </a>
                </li>
                <li>
                  <a
                    href="https://elfin-crop-e4b.notion.site/a3d4931c85264d50a061bdb10cba520c"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    リリースされました！
                  </a>
                </li>
                <li>
                  <a
                    href="https://elfin-crop-e4b.notion.site/KIRARINN-AI-5a7a106d8f4d4a61bc78016d3bbe649f"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    もっと見る
                  </a>
                </li>
              </ul>
            </div>
          );
        }
      })()}
      {(() => {
        if (
          !isIOS &&
          !isAndroid &&
          data.is_admin_user &&
          data.active_plan_name !== "Free"
        ) {
          return (
            <div>
              <h2>今月のリクエスト数(全体)</h2>
              <hr></hr>
              <ul>
                <li>プラン名: {data.active_plan_name}</li>
                <li>chatGPT使用回数: {data.api_call_logs.chatgpt_count}</li>
                <li>
                  総数: {data.api_call_logs.chatgpt_count}/
                  {data.api_call_logs.total_api_calls}
                </li>
              </ul>
            </div>
          );
        }
      })()}
      {(() => {
        if (!isIOS && !isAndroid && data.is_admin_user) {
          return (
            <div>
              <h2>
                利用人数 {data.user_numbers.number_of_all_users}/
                {data.user_numbers.number_of_free_users_quota +
                  data.user_numbers.number_of_paid_users_quota}
                人
              </h2>
              <hr></hr>
              <ul>
                <li>
                  無料枠: {data.user_numbers.number_of_free_users}/
                  {data.user_numbers.number_of_free_users_quota}人
                </li>
                <li>
                  有料枠: {data.user_numbers.number_of_paid_users}/
                  {data.user_numbers.number_of_paid_users_quota}人
                </li>
              </ul>
            </div>
          );
        }
      })()}
      {(() => {
        if (!isIOS && !isAndroid && data.is_admin_user) {
          return (
            <div>
              <h2>次回請求金額(※それぞれ月内の最大額で精算されます)</h2>
              <hr></hr>
              {(() => {
                if (data.has_invoice_info) {
                  return (
                    <div>
                      <ul>
                        <li>
                          {data.invoice_info.invoice_plan_name}プラン:{" "}
                          {data.invoice_info.invoice_plan_amount?.toLocaleString()}
                          円
                        </li>
                        <li>
                          有料人数枠:{" "}
                          {data.invoice_info.invoice_paid_users_quota}人 ×{" "}
                          {data.invoice_info?.invoice_paid_users_amount?.toLocaleString()}
                          円 ={" "}
                          {data.invoice_info.invoice_paid_users_amount_total?.toLocaleString()}
                          円
                        </li>
                        <li>
                          リクエストチケット:{" "}
                          {data.invoice_info.api_calls_amount?.toLocaleString()}
                          円
                        </li>
                        <li>
                          請求書送料(発行手数料込み):{" "}
                          {data.invoice_info.invoice_shipping_amount?.toLocaleString()}
                          円)
                        </li>
                      </ul>
                      <b className="total-amount">
                        合計金額:{" "}
                        {data.invoice_info.total_amount?.toLocaleString()}円
                        (消費税:{" "}
                        {data.invoice_info.tax_amount?.toLocaleString()}円)
                      </b>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <p>請求情報はありません</p>
                    </div>
                  );
                }
              })()}
              <button onClick={windowOpenToFrontSessionUrl}>
                プラン・有料人数枠の変更、リクエストチケットの購入はこちら
              </button>
            </div>
          );
        }
      })()}
      {(() => {
        if (!isIOS && !isAndroid && data.is_admin_user) {
          return (
            <div>
              <h2>お客様情報</h2>
              <hr></hr>
              {(() => {
                if (data.has_company_info) {
                  return (
                    <div>
                      <ul>
                        <li>会社名: {data.company_info.company_name}</li>
                        <li>郵便番号: {data.company_info.zip_code}</li>
                        <li>住所: {data.company_info.address}</li>
                        <li>
                          担当者名: {data.company_info.representatives_name}様
                        </li>
                        <li>
                          メールアドレス: {data.company_info.mail_address}
                        </li>
                        <li>電話番号: {data.company_info.phone_number}</li>
                        <li>
                          請求書発送方法:{" "}
                          {data.company_info.invoice_shipping_method}
                        </li>
                      </ul>
                      <button onClick={windowOpenToFrontSessionUrl}>
                        お客様情報の変更はこちら
                      </button>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <p>お客様情報は未登録です</p>
                      <button onClick={windowOpenToFrontSessionUrl}>
                        お客様情報の登録はこちら
                      </button>
                    </div>
                  );
                }
              })()}
            </div>
          );
        }
      })()}
      {(() => {
        if (data.is_admin_user !== "") {
          return (
            <div>
              <h2>利用規約</h2>
              <hr></hr>
              <p>
                利用規約は
                <a
                  href="https://kirarinn-ai.dott.dev/legal-documents"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  こちら
                </a>
                をご確認ください
              </p>
            </div>
          );
        }
      })()}
    </div>
  );
}
