import { isIOS, isAndroid } from "react-device-detect";
import "./Help.css";

export default function Help() {
  return (
    <div className="narrow page-padding">
      <h2>チームに追加</h2>
      <hr></hr>
      <p>
        本アプリをチームに追加していない場合、チームへの追加をお願いします。
        <br />
        チームに追加することで勤怠打刻をオープンなチャットで行えるようになり、同僚の行動を可視化することで、ミーティングなどのコミュニケーションをスムーズに行うサポートを提供します。
        <br />
        <br />
        アプリストアから「KIRARINN-AI」を検索していただき「チームへ追加」を選択後、任意のチームへ追加をしてください。
      </p>
      {
        <img
          className={isIOS || isAndroid ? "add-to-team-img" : ""}
          src="./images/add_to_team.png"
          alt="チームに追加"
        />
      }
      <h2>ホーム</h2>
      <hr></hr>
      <p>
        本タブに隣接する「ホーム」タブから、お知らせや利用規約などの確認を行えます。
      </p>
      <h2>マニュアル</h2>
      <p>
        本アプリの機能については
        <a
          href="https://elfin-crop-e4b.notion.site/KIRARINN-AI-5f2fdced576442e09e856b24dcd0b034"
          target="_blank"
          rel="noreferrer noopener"
        >
          マニュアル
        </a>
        ご参照下さい。
      </p>
    </div>
  );
}
